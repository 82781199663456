import { useContext, useEffect } from 'react';
import { makeRedirect, useChatClient, useChatClientWithCredentials } from '../services/utils';
import { InterviewStatus } from '../apiClient';
import ChatStateContext from '../models/ChatStateContext';

interface NewInterviewBuilderProps {
    linkInvitation: string;
    browserLanguage: string;
    queryLanguage: string;
    interviewExtId: string;
    querySearch: string;
    stimulusCellUid: string;
}

const NewInterviewBuilder = (props: NewInterviewBuilderProps) => {

    const chatClient = useChatClient();
    const chatClientWithCredentials = useChatClientWithCredentials();

    const isDev = window.location.hostname === 'localhost';

    const client = isDev ? chatClient : chatClientWithCredentials;

    const appState = useContext(ChatStateContext);

    useEffect(() => {
        (async () => {
            try {
                const startResponse = await client.start({
                    linkInvitation: props.linkInvitation,
                    queryLanguage: props.queryLanguage,
                    browserLanguage: props.browserLanguage,
                    extId: props.interviewExtId,
                    querySearch: props.querySearch,
                    stimulusCellUid: props.stimulusCellUid,
                    browserInfo: {
                        pixel_ratio: window.devicePixelRatio,
                        screen_height: window.screen.height,
                        screen_width: window.screen.width,
                        referrer: window.frames.top?.document.referrer || document.referrer
                    }
                });

                if (startResponse.interview_status !== InterviewStatus.Open) {
                    appState.setFinalScreen({
                        name: "SurveyClosed",
                        data: {
                            message: startResponse.messages[0].message,
                            redirectUrl: startResponse.redirect_url || undefined,
                            redirectMessage: !!startResponse.redirect_url ? startResponse.messages[1].message : undefined
                        }
                    });
                } else {
                    await makeRedirect(startResponse.redirect_url, 0);
                }
            } catch (error) {
                appState.setIsLoading(false);
                appState.setIsInterviewBuildSuccess(false);
            }
        })();
    }, [appState, client, props.browserLanguage, props.interviewExtId, props.linkInvitation, props.queryLanguage, props.querySearch, props.stimulusCellUid]);

    return (
        <>
            {isDev && <div>
                Building new interview...
            </div>}
        </>
    );
};

export default NewInterviewBuilder;