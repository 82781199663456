import { useContext, useEffect, useRef } from "react"
import MessagesContext from "../models/MessagesContext"
import Footer from "./Footer"
import MessageWrapper from "./MessageWrapper"
import ChatHeader from "./ChatHeader"
import { useChatHub } from "../services/useChatHub"
import { InterviewRole, InterviewStage } from "../apiClient"
import { DropdownOption } from "./Dropdown"
import MessageWrapperRefContext from "../models/MessageWrapperRefContext";
import Greeting from "./Greeting";
import { useTextDirection } from "../services/utils";
import VersionInfo from "./VersionInfo";
import ChatFooter from "./ChatFooter";
import { HubConnectionState } from "@microsoft/signalr";
import ChatStateContext from "../models/ChatStateContext";
import { MessageSide } from "../models/Message"

export interface ChatProps {
  interviewUid: string,
  browserLanguage: string,
  greetingLanguageSelectHandler: (languageCode: string) => void,
}

const Chat = (props: ChatProps) => {
  const footerRef = useRef<HTMLDivElement>(null);

  const messageWrapperRefContext = useContext(MessageWrapperRefContext);
  const messagesContext = useContext(MessagesContext);

  const appState = useContext(ChatStateContext);

  useTextDirection(appState.interviewLanguage);

  const { connection, accept, reject, send, aggregate, connect, callContinue, setLanguage, setScore } = useChatHub({
    interviewUid: props.interviewUid,
    browserLanguage: props.browserLanguage
  });

  useEffect(() => {
    if (connection.state === HubConnectionState.Disconnected) {
      connect();
    }
    else {
      callContinue();
    }
  }, [callContinue, connect, connection])

  const greetingLanguageSelectHandler = async (code: string) => {
    appState.setIsLoading(true);
    await setLanguage(code);

    window.location.search = window.location.search.replace(/[\?&]l=[^&]+/, '');
  }

  const acceptButtonHandler = async () => {
    await accept(props.browserLanguage);
  }

  const rejectButtonHandler = async () => {
    await reject({
      browserLanguage: props.browserLanguage,
      leave: false
    });
  }

  const onMessageSent = async (msg: string) => {
    if (appState.isInputBlocked)
      return;

    if (appState.interviewStage === InterviewStage.Acquaintance) {
      await sendAcquaintanceMessageToServer(msg);
    }
    else
      await sendMessageToServer(msg);
  }

  const sendAcquaintanceMessageToServer = async (msg: string) => {

    appState.setIsInputBlocked(true);

    messagesContext.setIsModeratorTypingAsync(true);

    await send({
      question_id: "Acquaintance",
      text: msg,
      author: InterviewRole.User
    });
  }

  const sendMessageToServer = async (msg: string) => {
    await send({
      question_id: appState.currentQuestionId,
      text: msg,
      author: InterviewRole.User
    })

    var messageId = messagesContext.messages.filter(m => m.side === MessageSide.User).pop();

    await aggregate(
      messageId?.id as number,
      appState.currentQuestionId,
      true
    )
  }

  const sendAggregateToServer = async (messageId: number, isStartDown: boolean) => {
    await aggregate(
      messageId,
      appState.currentQuestionId,
      isStartDown
    )
  }

  const onScoreSet = async (scoreNum: number, performRedirect: boolean) => {
    return setScore({ score: scoreNum, performRedirect: performRedirect })
  }

  let topContentPart = <></>;
  let overallContent = <></>;

  if (appState.interviewStage === InterviewStage.Greeting && appState.greetingModel) {
    let languageDropdownOptions: DropdownOption[] = appState.greetingModel.languages.map(x => {
      return {
        name: x.name,
        code: x.code,
        fn: () => { greetingLanguageSelectHandler(x.code) }
      }
    });

    topContentPart = <Greeting
      acceptButtonHandler={acceptButtonHandler}
      rejectButtonHandler={rejectButtonHandler}
      languageDropdownOptions={languageDropdownOptions}
      selectedLanguageCode={appState.interviewLanguage}
      interviewUid={props.interviewUid}
      footerText={appState.footerText} />
  }

  if (!appState.isLoading) {
    if (appState.interviewStage === InterviewStage.Greeting && appState.greetingModel) {
      overallContent = topContentPart
    }
    else {
      if (appState.chatLeaveTexts !== null) {
        overallContent = (<>
          <ChatHeader
            assistantName={appState.assistantName}
            leaveTexts={appState.chatLeaveTexts}
            browserLanguage={props.browserLanguage}
            reject={reject}
            onlineStatusText={appState.agentOnlineStatus}
            onScoreSet={(score) => onScoreSet(score, false)}
          />
          <MessageWrapper
            imageUrl={appState.assistantImageUrl}
            connectionState={appState.connectionState}
            onScoreSet={(score) => onScoreSet(score, true)}
            ref={(ref) => {
              if (ref && messageWrapperRefContext && !messageWrapperRefContext.messageWrapperRef?.current) {
                messageWrapperRefContext.setMessageWrapperRef({ current: ref });
              }
            }} />
          <Footer
            ref={footerRef}
            onMessageSent={onMessageSent}
            onAggregateMessageSent={sendAggregateToServer}
            disabled={appState.isInputBlocked}
            interviewStatus={appState.interviewStatus}
            enabledText={appState.chatEnabledText}
            disabledText={appState.chatDisabledText}
            conversationEndText={appState.chatConversationEndText}
            connectionState={appState.connectionState} />
          <div>
            <ChatFooter text={appState.footerText} />
          </div>
        </>)
      }
    }
  }

  return (
    <>
      <VersionInfo />
      {overallContent}
    </>)
}

Chat.whyDidYouRender = true;

export default Chat